import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { router } from "./App";
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux'
import store from './store'
import { ToastContainer } from 'react-toastify';
import { getThemeConfigData } from './ConfigForTheme';
//import './assets/fonts/sofiapro-light/SofiaPro-Light.ttf';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <RouterProvider router={router} />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </Provider>,

)

const themeConfig = getThemeConfigData();
// alert("nahi gaya")

if (themeConfig && themeConfig.color) {
  // alert("chal gaya")
  document.documentElement.style.setProperty('--themeColor', themeConfig.color);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
