
import { env } from "./env";
import AcademixThumb from "../src/assets/thumbnails/academix.png"
import BigAcademyThumb from "../src/assets/thumbnails/bigAcademy.jpg"

import AcademixSideMenu from "../src/assets/academix-img/logo.png"
import BigAcademySideMenu from "../src/assets/academix-img/logo_big.png"

export const getThemeConfigData = () => {
  switch (env.environment) {
    case "dev":
      return {
        logo: "/images/logo/logo_academix.png",
        text: "Academix",
        color: "#3567DF",
        videoThumbnail:AcademixThumb,
        sideMenuLogo :AcademixSideMenu
       
      };

    case "big_academy":
      return {
        logo: "/images/logo/logo_academix.png",
        text: "Big Academy",
        color: "#d0312eff",
        svgIconsColor : "#CE1B1B",
         videoThumbnail:BigAcademyThumb,
         sideMenuLogo :BigAcademySideMenu
       
      };

   

      break;

    default:
      break;
  }
};





