import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import RestApi from "src/service/RestApi";

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);
  const navigate = useNavigate();

  const login = async (data) => {    
    
    setUser(data);
    navigate("/profile", { replace: true });
  };

  const logout = () => {
    logOutUser(user)
    setUser(null);
   // window.location.reload();
    navigate("/", { replace: true });
  };

  const logouthome = () => {
    logOutUser(user)
    setUser(null);
   window.location.reload();
   
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,logouthome
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const logOutUser = async (userToken ) => {
  const response = await RestApi.logout(userToken)
  if (response.data.status == 200) {

    return response.data.status

  }

  //return response;
};
